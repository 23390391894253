import React, {Fragment, useEffect} from "react";
import {Container, makeStyles, Typography} from "@material-ui/core";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Box from "@material-ui/core/Box";
import CompanySidebar from "../components/CompanySidebar";
import {useParams} from "react-router-dom";
import {useCollectionData, useCollectionDataOnce} from "react-firebase-hooks/firestore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import firebase from "firebase";
import Grid from "@material-ui/core/Grid";
import ChallengeProgress from "../components/ChallengeProgress";
import moment from "moment";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {useConfirm} from "material-ui-confirm";
import ChallengeDialog from "../components/ChallengeDialog";
import {AddCircle, Group, Person} from "@material-ui/icons";
import {produce} from "immer";
import classNames from 'classnames';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    card: {
        width: 450,
        background: "#00A4CF"
    },
    newcard: {
        background: "#cccccc"
    },
    newCardIcon: {
        color: "white",
        fontSize: 120
    },
    cardContent: {
        minHeight: 250,
        paddingBottom: 0
    },
    headline: {
        color: "white",
        textAlign: "center"
    },
    header: {
        color: "rgba(255,255,255,0.5)"
    },
    teamColor: {
        color: "rgba(255,255,255,0.8)"
    },
    button: {
        color: "#cccccc"
    },
    actionButton: {
        color: "#f8ca51"
    },
    teamHeadLine: {
        fontWeight: "bold",
        textAlign: "center",
        color: "white"
    },
    members: {
        textAlign: "center",
        "& svg": {
            verticalAlign: "bottom"
        }
    },
    appBarSpacer: theme.mixins.toolbar,
}));

const clamp = (val, min, max) => Math.min(Math.max(min, val), max);

function TeamRow({id}) {
    const classes = useStyles();
    const query = firebase.firestore().collection('challenges').doc(id).collection('teams');

    const [teams, loading, error] = useCollectionData(
        query
    );
    return (<Container>
        <Grid container wrap="wrap">
            {teams && teams.map((item, index) =>
                <Grid key={index} item xs>
                    <Typography align="center" variant="body2" className={classes.teamColor}>{item.name}</Typography>
                    <div className={classes.members}>
                        <Typography
                            variant="body2"
                            display="inline"
                            className={classes.teamColor}>
                            {item.members.length}
                        </Typography>
                        {[
                            null,
                            <Person fontSize="small" className={classes.teamColor}/>,
                            <Group fontSize="small" className={classes.teamColor}/>][clamp(item.members.length, 0, 2)]}
                    </div>
                </Grid>)}
        </Grid>
    </Container>);
}

function CMSPage() {
    const classes = useStyles();
    const {id} = useParams();
    const [openChallenge, setOpenChallenge] = React.useState(null);
    const confirm = useConfirm();

    const [challenges, loading, error] = useCollectionData(
        firebase.firestore().collection('challenges').where("company", "==", id).orderBy("endDate", "desc"), {"idField": "id"}
    );

    if (error) {
        console.error(error);
    }

    const edit = async (challenge) => {
        const snapshot = await firebase.firestore().collection('challenges').doc(challenge.id).collection("teams").get();

        const teams = await snapshot.docs.map((s) => {
            return {...s.data()};
        });
        setOpenChallenge(produce(challenge, draftState => {
            draftState.teams = teams;
        }));
    };

    const pastChallenge = (c) => c.endDate.toDate() < new Date();

    return (<div className={classes.root}>
        <PlusValuesAppBar drawerWidth={drawerWidth}/>
        <CompanySidebar id={id}/>
        <main className={classes.content}>
            <div className={classes.appBarSpacer}/>
            <Box p={5}>
                <Grid container spacing={3}>
                    <Grid item><Card className={classNames(classes.card, classes.newcard)}>
                        <CardActionArea onClick={() => {
                            setOpenChallenge({
                                company: id,
                                title: '',
                                tagline: '',
                                teams: []
                            });
                        }}>
                            <CardContent>
                                <Grid container direction="column" justify="space-between"
                                      className={classes.cardContent}>
                                    <Grid item>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Typography className={classes.header}>#Value</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={classes.header}>{moment().startOf('month').add(1, 'month').format("MMMM YYYY")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{textAlign: "center"}}>
                                        <AddCircle className={classes.newCardIcon}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" className={classes.headline}>Legg til en ny
                                            utfordring!</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent></CardActionArea>
                        <CardActions>
                            <Button size="small" color="default" className={classes.button}
                                    style={{visibility: "hidden"}}>Dummy</Button>
                        </CardActions>
                    </Card></Grid>
                    {challenges && challenges.map((challenge, index) => (
                        <Grid item key={index}><Card className={classes.card}>
                            <CardActionArea onClick={() => edit(challenge)}>
                                <CardContent>
                                    <Grid container direction="column" justify="space-between"
                                          className={classes.cardContent}>
                                        <Grid item>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography
                                                        className={classes.header}>{challenge.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        className={classes.header}>{moment(challenge.startDate.toDate()).format("dd, ll")} - {moment(challenge.endDate.toDate()).format("dd, ll")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6"
                                                        className={classes.headline}>{challenge.tagline}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ChallengeProgress success={challenge.success} goal={challenge.goal}/>
                                        </Grid>
                                        <Grid item>
                                            <TeamRow id={challenge.id}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                {pastChallenge(challenge) &&
                                    <Button size="small" color="default" className={classes.actionButton}
                                            onClick={() => edit(challenge)}>
                                        Rediger
                                    </Button>}
                                {pastChallenge(challenge) &&
                                    <Button size="small" color="default" className={classes.button}
                                            onClick={async () => {
                                                try {
                                                    const docRef = firebase.firestore().collection('challenges').doc(challenge.id);
                                                    const scores = await docRef.collection('score').get();

                                                    if (scores.empty) {
                                                        await confirm({title: 'Vil du slette "' + (challenge.tagline) + '"?'});
                                                        await docRef.delete();
                                                    } else {
                                                        await confirm({
                                                            title: '"' + challenge.tagline + '" kan ikke slettes siden det allerede er registret stemmer på den!',
                                                            cancellationButtonProps: {style: {display: "none"}}
                                                        });
                                                    }
                                                } catch (error) {
                                                    if (error) {
                                                        console.error(error);
                                                    }
                                                }
                                            }}>
                                        Slett
                                    </Button>}
                                <Button style={{visibility: "hidden"}}>Test</Button>
                            </CardActions>
                        </Card></Grid>))}
                </Grid>
            </Box>
        </main>
        {openChallenge && <ChallengeDialog challenge={openChallenge} setOpenChallenge={setOpenChallenge} id={id}/>}
    </div>);
}

export default CMSPage;
