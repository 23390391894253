import React, {Component, Fragment, useEffect, useState} from 'react'
import firebase from "firebase/app";
import {Container, makeStyles, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom"
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ArrowForward, Visibility, VisibilityOff} from "@material-ui/icons";
import {useDocumentDataOnce} from "react-firebase-hooks/firestore";
import Button from "@material-ui/core/Button";

// Configure FirebaseUI.
const uiConfig = {
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Github as the auth provider.
    signInOptions: [{
        provider: "microsoft.com",
        customParameters: {
            tenant: "978e0913-c8dd-46fd-9cc1-88eea04e2af3"
        }
    }]
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 420,
        padding: theme.spacing(1),
    },
    textField: {
        width: "100%"
    }
}));

export default function Login() {
    const history = useHistory();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const companyLogin = async (company, userCompanyId, email) => {
        if (company.signInOptions && company.signInOptions.provider !== "email") {
            const provider = new firebase.auth.OAuthProvider(company.signInOptions.provider);
            const customParameters = {};

            Object.keys(company.signInOptions.customParameters).forEach(function (key) {
                const value = company.signInOptions.customParameters[key];
                customParameters[key] = value === "[email]" ? email : value;
            });
            provider.setCustomParameters(customParameters);
            try {
                await firebase.auth().signInWithPopup(provider);
                if (userCompanyId !== company.id) {
                    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({"company": company.id})
                }
                history.push("/");
            } catch (error) {
                console.error(error);
            }
        } else {
            setShowPassword(true);
        }
    };

    const next = async () => {
        if (showPassword) {
            try {
                await firebase.auth().signInWithEmailAndPassword(email, password);
                const tokenResult = await firebase.auth().currentUser.getIdTokenResult();
                if (tokenResult.claims.role !== "admin") {
                    await firebase.auth().signOut();
                    console.error("User is not admin!");
                    setEmailError(true);
                }
                history.push("/");
            } catch (error) {
                console.error(error.message);
                setPasswordError(true);
            }
        } else if (email) {
            if (email.indexOf("@") === -1) {
                setEmailError(true);
            } else {
                const userQuery = await firebase.firestore().collection('users').where("email", "==", email).limit(1).get();

                if (!userQuery.empty) {
                    const user = userQuery.docs[0].data();
                    const company = await firebase.firestore().collection('companies').doc(user.company).get();
                    await companyLogin({...company.data(), id: company.id}, user.company, user.email);
                } else {
                    const domain = email.split("@")[1];
                    const companyQuery = await firebase.firestore().collection('companies').where("domain", "==", domain).get();
                    const company = {id: companyQuery.docs[0].id, ...companyQuery.docs[0].data()};
                    if (company.signInOptions.provider !== "email") {
                        await companyLogin(company, undefined, email);
                    } else {
                        setEmailError(true);
                    }
                }
            }
        }
    };
    return (
        <Grid container
              spacing={0}
              alignItems="center"
              justify="center"
              style={{minHeight: "100vh"}}>
            <Paper className={classes.root}>
                <Typography variant="h4">Velkommen til PlusOne</Typography>
                <form onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    next();
                }}>
                    <TextField
                        required
                        error={emailError}
                        id="email"
                        label="E-post"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setEmailError(false);
                        }}
                        className={classes.textField}
                        margin="normal"
                    />
                    {showPassword && <TextField
                        required
                        error={passwordError}
                        id="password"
                        type={visibility ? "text" : "password"}
                        label="Passord"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                            setPasswordError(false);
                        }}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="Neste"
                                    onClick={() => setVisibility(true)}
                                >
                                    {visibility ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />}
                    <Button variant="contained" color="primary" type={'submit'}>
                        Next
                    </Button>
                </form>
            </Paper>
        </Grid>
    );
}
