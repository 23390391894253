import {makeStyles, TablePagination, Typography} from "@material-ui/core";
import React, {forwardRef, Fragment, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import firebase from "firebase/app";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useHistory} from "react-router-dom"
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import {
    Add,
    AddBox, ArrowDownward,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, Launch, Remove,
    SaveAlt, Search, ViewColumn
} from "@material-ui/icons";
import {useConfirm} from "material-ui-confirm";

const useStyles = makeStyles( {
    root: {
        width: '100%',
        maxWidth: 1200
    },
    tableWrapper: {
        maxHeight: 440,
        overflow: 'auto',
    },
    logo: {
        maxHeight: "30px",
        maxWidth: "120px"
    },
    logoBackground: {
        background: "lightGray",
        display: "inline-block",
        cursor: "pointer",
        border: "2px solid transparent",
        "&:hover": {
            border: "2px solid black"
        },
        "& img": {
            maxWidth: "200px",
            maxHeight: "50px"
        }
    }
});

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    Open: forwardRef((props, ref) => <Launch {...props} ref={ref} />),


    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function CompanyTable() {
    const classes = useStyles();
    const history = useHistory()
    const confirm = useConfirm();
    const [companies, loading, error] = useCollectionData(
        firebase.firestore().collection('companies'), {"idField": "id"}
    );

    if (error) {
        debugger;
    }

    const columns = [{id: "logo",label: "", format: (src)=><div className={classes.logoBackground}><img src={src} alt="logo" className={classes.logo}/></div>}, {id: "name",label: "Bedrift", whenUndefinded:"Ukjent"}, {id: "domain",label: "Domene"}, {id: "when",label: "Opprettet"}, ];
    return (<Fragment>
        {loading &&<Fragment></Fragment>}
        {error&&<Typography>{error.message}</Typography>}
        {companies&&
            <Paper className={classes.root}>
                <MaterialTable
                    emptyRowsWhenPaging={false}
                    icons={tableIcons}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Søk",
                            searchTooltip: "Søk"
                        },
                        header: {
                            actions: ''
                        }
                    }}
                    columns={[
                        {field: "logo",title: "Logo",render:(data)=>data.logo&&<div className={classes.logoBackground}><img src={data.logo} alt="logo" onClick={()=>{
                                history.push(`/company/${data.id}`)
                        }
                            }/></div>},
                        {field: "name",title: "Navn"},
                        {field: "domain",title: "Domene"},
                        {field: "contact",title: "Kontaktperson"},
                        {field: "when",title: "Opprettet"},
                    ]} data={companies} title={"Organisasjoner"}
                    options={{actionsColumnIndex: -1, paging: false}}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Legg til firma',
                            isFreeAction: true,
                            onClick: async (event) => {
                                const ref = await firebase.firestore().collection("companies").add({});
                                history.push(`/company/${ref.id}`);
                            }
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Slett',
                            onClick: async (event, rowData) => {
                                try {
                                    await confirm({title: 'Vil du slette ' + (rowData.name || 'ukjent firma') + '?'});
                                    await firebase.firestore().collection('companies').doc(rowData.id).delete();
                                } catch (error) {
                                    if (error) {
                                        console.error(error);
                                    }
                                }
                            }
                        },
                        {
                            icon: tableIcons.Open,
                            tooltip: 'Åpne',
                            onClick: async (event, rowData) => {
                                history.push(`/company/${rowData.id}`)
                            }
                        }
                    ]}
                />
            </Paper>}
    </Fragment>);
}


export default CompanyTable;
