import React from "react";
import {Container, makeStyles} from "@material-ui/core";
import classNames from 'classnames';

const useStyles = makeStyles( theme=>({
    root: {
        background: "#0083a6",
        borderRadius: 100,
        width: "100%",
        height: 20,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2
    },
    lines: {
        borderRight: "1px solid #34b6d9",
        height: 14,
        width: "10%",
        display: "inline-block",
        boxSizing: "border-box",
        "&:first-child": {
            borderLeft: "1px solid #34b6d9",
        },
        "&.success": {
            borderRightColor: "#f8ca51",
        }
    },
    labels: {
        width: "100%",
        height: 20,
        paddingLeft: 10,
        paddingRight: 10,
        position: "relative",
        "& .label": {
            display: "block",
            position: "absolute",
            top: 0,
            width: 20,
            textAlign: "center",
            color: "white",
            "&.goal": {
                right: 0
            },
            "&.success": {
                left: 0,
                color: "#f8ca51",
                marginLeft: -15
            }
        }
    }
}));

function ChallengeProgress({goal,success}) {
    const classes = useStyles();
    return (<Container>
        <Container className={classes.labels}><span className="label goal">{goal}</span><span className="label success" style={{left:Math.floor(10*success)+"%"}}>{Math.round((goal/10)*success)}</span></Container>
        <Container className={classes.root}>
            {[...Array(10)].map((el, index, i) => (<div key={index} className={classNames(classes.lines, { success: index+1===success })}/>))}
    </Container></Container>);
}

export default ChallengeProgress;