import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";
import "typeface-roboto";
import "typeface-roboto-mono";
import {ConfirmProvider} from "material-ui-confirm";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import "moment/locale/nb";

moment.locale("nb");

const theme = createMuiTheme({
    palette: {
    }
});

ReactDOM.render(<MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider  locale="nb" utils={MomentUtils}>
    <ConfirmProvider defaultOptions={{title:"Er du sikker?",confirmationText:"OK",cancellationText:"Avbryt"}}>
    <CssBaseline>
        <App />
    </CssBaseline></ConfirmProvider></MuiPickersUtilsProvider>
</MuiThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
