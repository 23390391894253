import {makeStyles, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import React, {createRef, Fragment, useCallback} from "react";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import TextField from "@material-ui/core/TextField";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CompanySidebar from "../components/CompanySidebar";
import Dropzone, {useDropzone} from "react-dropzone";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete} from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const drawerWidth = 240;

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        width: 300,
        display: "block"
    },
    appBarSpacer: theme.mixins.toolbar,
    dropZone: {
        background: "#eeeeee",
        border: "1px solid #cccccc",
        padding: "20px",
        textAlign: "center",
        textDecoration: "underline"
    },
    logoContainer: {
        '& img': {
            maxWidth: "100%"
        }
    },
    menuTextFieldTitle: {
        width: 200,
        paddingRight: "20px"
    },
    menuTextFieldContent: {
        width: 400,
        paddingRight: "20px"
    },
    menuTextFieldSave: {
        verticalAlign: "bottom",
        marginBottom: "10px"
    },
}));

function CompanyPage() {
    const classes = useStyles();
    const { id } = useParams();
    const docRef = firebase.firestore().collection('companies').doc(id);
    const [addMenuItem, setAddMenuItem] = React.useState(false);
    const [menuItemTitle, setMenuItemTitle] = React.useState("");
    const [menuItemUrl, setMenuItemUrl] = React.useState("");
    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
            //const reader = new FileReader()
            const storageRef = await firebase.storage().ref().child(id).child(`${file.name}`);
            const snapshot = await storageRef.put(file);
            const path = await snapshot.ref.getDownloadURL();
            await docRef.update({logo:path});
    }, []);


    const maxSize = 1048576*5; //5mb

    const [company, loading, error] = useDocumentData(
        docRef, {"idField": "id"}
    );

    const menuRef = docRef.collection("menu");
    const [menu, menuLoading, menuError] = useCollectionData(
        menuRef, {"idField": "id"}
    );

    return (<div className={classes.root}>
        <PlusValuesAppBar drawerWidth={drawerWidth}/>
        <CompanySidebar id={id}/>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Fragment>{company && <form noValidate autoComplete="off">
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                required
                                id="standard-required"
                                label="Organisasjon"
                                className={classes.textField}
                                margin="normal"
                                value={company.name}
                                onChange={(event)=>{
                                    docRef.update({name: event.target.value})
                                }}
                            />
                            <TextField
                                id="standard"
                                label="Domene"
                                className={classes.textField}
                                margin="normal"
                                value={company.domain}
                                onChange={(event)=>{
                                    docRef.update({domain: event.target.value})
                                }}
                            />
                            <TextField
                                id="standard"
                                label="Kontaktperson"
                                className={classes.textField}
                                margin="normal"
                                value={company.contact}
                                onChange={(event)=>{
                                    docRef.update({contact: event.target.value})
                                }}
                            />
                            <TextField
                                id="standard"
                                label="Telefon til kontakt"
                                className={classes.textField}
                                margin="normal"
                                value={company.contactPhone}
                                onChange={(event)=>{
                                    docRef.update({contactPhone: event.target.value})
                                }}
                            />
                            <TextField
                                id="standard"
                                label="Epost til kontakt"
                                className={classes.textField}
                                margin="normal"
                                value={company.contactEmail}
                                onChange={(event)=>{
                                    docRef.update({contactEmail: event.target.value})
                                }}
                            />
                            <TextField
                                id="standard"
                                label="Beskjed ingen aktiv utfordring"
                                className={classes.textField}
                                margin="normal"
                                value={company.noChallengeMessage || ''}
                                onChange={(event)=>{
                                    docRef.update({noChallengeMessage: event.target.value ? event.target.value : firebase.firestore.FieldValue.delete()})
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Dropzone
                                onDrop={onDrop}
                                accept="image/png, image/gif image/jpg"
                                minSize={0}
                                maxSize={maxSize}
                            >
                                {({
                                      getRootProps,
                                      getInputProps,
                                      isDragActive,
                                      isDragReject
                                  }) => {
                                    return (
                                        <div {...getRootProps({className: classes.dropZone})}>
                                            <div className={classes.logoContainer}>
                                                {company.logo && <img src={company.logo}/>}
                                            </div>
                                            <input {...getInputProps()} />
                                            {isDragActive
                                                ? "Slipp filen din her!"
                                                : "Trykk her eller trekk en fil hit for å laste opp logo."}
                                            {isDragReject && "Det er bare mulig å laste opp en jpeg, gif eller png fil som logo!"}
                                        </div>
                                    );
                                }}
                            </Dropzone>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} item>
                            <Typography variant={"h6"}>Meny</Typography>
                            {menu && menu.map((menuItem, index)=><div key={index}>
                                <Typography>{menuItem.title}: {menuItem.url}<IconButton size={"small"} aria-label="Slett" onClick={async () => {
                                    await menuRef.doc(menuItem.id).delete();
                                }}><Delete/></IconButton>
                                </Typography>
                            </div>)}
                            {!addMenuItem && <Button variant="contained" onClick={()=>setAddMenuItem(true)}>Legg til meny</Button>}
                            {addMenuItem && <div>
                                <TextField
                                    id="standard"
                                    label="Title"
                                    className={classes.menuTextFieldTitle}
                                    margin="normal"
                                    value={menuItemTitle}
                                    onChange={(event)=>
                                        setMenuItemTitle(event.target.value)
                                    }
                                />
                                <TextField
                                    id="standard"
                                    label="Url"
                                    className={classes.menuTextFieldContent}
                                    margin="normal"
                                    value={menuItemUrl}
                                    onChange={(event)=>
                                        setMenuItemUrl(event.target.value)
                                    }
                                />
                                <IconButton size={"small"} aria-label="Legg til" className={classes.menuTextFieldSave} onClick={async () => {
                                    if (menuItemUrl.length > 0 && menuItemTitle.length > 0) {
                                        await menuRef.add({title:menuItemTitle,url:menuItemUrl});
                                        setMenuItemUrl("");
                                        setMenuItemTitle("");
                                        setAddMenuItem(false);
                                    }
                                }}><Add/></IconButton>
                            </div>}
                        </Grid>
                    </Grid>
                </form>}</Fragment>
        </Container></main>
    </div>);
}

export default CompanyPage;
