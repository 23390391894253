import React, {Fragment} from "react";
import { Redirect, Route } from "react-router-dom";
import "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import firebase from "firebase/app";

function PrivateRoute({ children, ...rest }) {
    const [user, initialising, error] = useAuthState(firebase.auth());
    return (
        initialising?<Fragment/>:
        <Route
            {...rest}
            render={( props ) => (
                user !== null ?
                    children
                 :
                    <Redirect to="/signin"/>
                )
            }
        />
    );
}

export default PrivateRoute;
