import {makeStyles, TablePagination, Typography} from "@material-ui/core";
import React, {forwardRef, Fragment, useEffect} from "react";
import firebase from "firebase/app";
import {useCollectionData} from "react-firebase-hooks/firestore";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import {
    AddBox, ArrowDownward,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, Remove,
    SaveAlt, Search, ViewColumn
} from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles( {
    root: {

    },
});
function TvList({company}) {
    const classes = useStyles();
    const [tv, loading, error] = useCollectionData(
        firebase.firestore().collection('tv').where("company","==", company), {"idField": "id"});
    return (<MaterialTable
            icons={tableIcons}
            localization={{
                toolbar: {
                    searchPlaceholder: "Søk",
                    searchTooltip: "Søk",
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} av {count}'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: '',
                    deleteTooltip: "Slett",
                    editTooltip: "Rediger",
                    filterRow: {
                        filterTooltip: 'Filtrer'
                    },
                    editRow: {
                        deleteText: "Er du sikker på at du vil slette denne raden?",
                        cancelTooltip: "Avbryt",
                        saveTooltip: "Lagre"
                    }
                }
            }}
            columns={[
                {field: "name",title: "Navn"},
            ]} data={tv}
            options={{actionsColumnIndex: -1, search: false, toolbar:false, showTitle:false, header:false, paging:false}}

            editable={{
                onRowUpdate: async (newData, oldData) => {
                    await firebase.firestore().doc(`tv/${oldData.id}`).update(
                        newData
                    );
                },
                onRowDelete: async oldData =>  {
                    await firebase.firestore().doc(`tv/${oldData.id}`).delete();
                }
            }
            }
        ></MaterialTable>);
}

export default TvList;