import React, {useState} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import DashboardPage from "../pages/DashboardPage";
import Login from "../pages/Login";
import CompanyPage from "../pages/CompanyPage";
import UsersPage from "../pages/UsersPage";
import TvPage from "../pages/TvPage";
import CMSPage from "../pages/CMSPage";
import StatsPage from "../pages/StatsPage";

export default () => {
    return (
        <Router>
            <Switch>
                <PrivateRoute exact path="/">
                    <DashboardPage/>
                </PrivateRoute>
                <Route path="/signin">
                    <Login/>
                </Route>
                <Route path="/company/:id/users" children={<UsersPage />} />
                <Route path="/company/:id/tv" children={<TvPage />} />
                <Route path="/company/:id/content" children={<CMSPage />} />
                <Route path="/company/:id/stats" children={<StatsPage />} />
                <Route path="/company/:id" children={<CompanyPage />} />
            </Switch>
        </Router>
    );
};