import React, {Fragment, useEffect} from "react";
import {makeStyles, Typography} from "@material-ui/core";
import CompanyTable from "../components/CompanyTable";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import firebase from "firebase/app";
import {useHistory} from "react-router-dom"

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
}));
function DashboardPage() {
    const classes = useStyles();
    const history = useHistory();

    return (<div className={classes.root}>
        <PlusValuesAppBar/>
        <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box p={5}>
            <CompanyTable/>
        </Box>
        </main>
    </div>);
}

export default DashboardPage;