import React, {Fragment, useEffect} from "react";
import {makeStyles, Typography} from "@material-ui/core";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Box from "@material-ui/core/Box";
import CompanySidebar from "../components/CompanySidebar";
import {useParams} from "react-router-dom";
import ReactCodesInput from 'react-codes-input';
import "react-codes-input/lib/react-codes-input.min.css";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import TvList from "../components/TvList";

const drawerWidth = 240;

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: theme.mixins.toolbar,
    tvUrl: {
        fontFamily: "Roboto Mono, monospace",
        fontWeight: "bold"
    },
    enteredValue: {
        fontFamily: "Roboto Mono, monospace",
        fontWeight: "bold"
    },
}));
function TvPage() {
    const classes = useStyles();
    const { id } = useParams();
    const [code, setCode] = React.useState("");
    const [document, setDocument] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [openTvName, setOpenTvName] = React.useState(false);
    const [tvName, setTvName] = React.useState("");

    const handleClose = () => {
        setOpenTvName(false);
    };

    const loadDocument = async (completedCode) => {
        const result = await firebase.firestore().collection("tv").where("code","==", completedCode).where("company","==", null).get();
        if (!result.empty) {
            setDocument({...result.docs[0].data(),id:result.docs[0].id});
        } else {
            setErrorMessage("Kunne ikke finne koden!");
        }
    };

    return (<div className={classes.root}>
        <PlusValuesAppBar drawerWidth={drawerWidth}/>
        <CompanySidebar id={id}/>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Box p={5}>
                <Typography variant="h2" gutterBottom={true}>Vis resultatene på din TV</Typography>
                <Typography paragraph={true}>Hvis du har mulighet for å åpne en nettleser på din tv så kan du koble den til PlusCulture.</Typography>
                <Typography paragraph={true}>Gå til nettsiden: <span className={classes.tvUrl}>tv.plusculture.no</span></Typography>
                <Typography paragraph={true}>Skriv inn koden du får opp på skjermen her:</Typography>
                <ReactCodesInput
                    codeLength={5}
                    letterCase="lower"
                    initialFocus={true}
                    customStyleComponent={{ maxWidth: '300px'}}
                    value={code}
                    classNameEnteredValue={classes.enteredValue}
                    onChange={res => {
                        console.log(`errorMessage: ${errorMessage}`);
                        setErrorMessage(null);
                        setCode(res);
                        if (res.length === 5) {
                            loadDocument(res);
                        }
                    }}/>
                <Box pt={2}/>
                {errorMessage && <Typography paragraph={true} key={errorMessage}>{errorMessage}</Typography>}
                 <Button variant="outlined" color="primary" disabled={!document} onClick={()=>setOpenTvName(true)}>Koble til</Button>
                <Dialog open={openTvName} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText>
                            Gi Tven et navn slik at du vet hvilken skjermer vi viser resultatene på.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="tv_name"
                            label="Navn på tv"
                            value={tvName}
                            onChange={event => {
                                setTvName(event.target.value);
                            }}
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Avbryt
                        </Button>
                        <Button onClick={async ()=>{
                            handleClose();
                            await firebase.firestore().collection("tv").doc(document.id).update({company:id, name:tvName});
                            setTvName("");
                            setCode("");
                        }} color="primary">
                            Legg til
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box pt={2}/>
                <TvList company={id}/>
            </Box>
        </main>
    </div>);
}

export default TvPage;