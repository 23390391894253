import firebase from "firebase/app";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {AccountCircle} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, {Fragment, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";

type Props = {
    drawerWidth: number, // foo is required.
};

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    title: {
        flexGrow: 1,
    },
    appBar: props => ({
        width: `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: props.drawerWidth,
    })
}));

function PlusValuesAppBar({drawerWidth}) {
    const classes = useStyles({drawerWidth});
    const [user, initialising, error] = useAuthState(firebase.auth());
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };
    const menuId = 'account-menu';
    return (<Fragment>
        {user &&
        (<AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    Admin
                </Typography>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
            </Toolbar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!anchorEl}
                onClose={handleProfileMenuClose}
            >
                <MenuItem onClick={async () => {
                    await firebase.auth().signOut();
                    handleProfileMenuClose();
                    history.push(`/`);
                }}>Logg ut</MenuItem>
            </Menu>
        </AppBar>)
        }</Fragment>);
}

PlusValuesAppBar.defaultProps = {
    drawerWidth: 0,
};

export default PlusValuesAppBar;