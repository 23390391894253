import firebase from "firebase/app";

const config = {
    firebase_config: {
        apiKey: "AIzaSyB0MRNQMa_JAiyoHybALamj4h_zGOq6jQM",
        authDomain: "plus-values.firebaseapp.com",
        databaseURL: "https://plus-values.firebaseio.com",
        projectId: "plus-values",
        storageBucket: "plus-values.appspot.com",
        messagingSenderId: "602591998260",
        appId: "1:602591998260:web:47e25fd301a82304097561",
        measurementId: "G-C7HQ3LNNJF"
    },
    firebase_providers: ['microsoft.com'],//[firebase.auth.EmailAuthProvider.PROVIDER_ID],
    initial_state: {
        locale: "en"
    }
};


export default config;