import {Checkbox, makeStyles, TablePagination, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import React, {forwardRef, useCallback} from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Container from "@material-ui/core/Container";
import CompanySidebar from "../components/CompanySidebar";
import MaterialTable from "material-table";
import {
    AddBox, ArrowDownward,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, LockOpen, Remove,
    SaveAlt, Search, ViewColumn
} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useConfirm} from "material-ui-confirm";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from "react-dropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { v1 as uuidv1 } from 'uuid';
import CircularProgress from "@material-ui/core/CircularProgress";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    ResendPassword: forwardRef((props, ref) => <LockOpen {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const maxSize = 1048576*5; //5mb

const drawerWidth = 240;

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    appBarSpacer: theme.mixins.toolbar,
    tableWrapper: {
        maxHeight: 440,
        overflow: 'auto',
    },
    profileImage: {
        objectFit: "cover",
        width: 40,
        height: 40
    },
    dropZone: {
        background: "#eeeeee",
        border: "1px solid #cccccc",
        padding: "20px",
        textAlign: "center",
        textDecoration: "underline",
        width: 180,
        height: 200,
        paddingBottom: 20
    },
    logoContainer: {
        '& img': {
            maxWidth: "100%"
        }
    },
    formControl: {

    },
}));

const cleanMap=(map)=> {
    const result = {};
    Object.entries(map).forEach(entry => {
        let key = entry[0];
        let value = entry[1];
        result[key] = value===undefined?null:value;
    });
    debugger;
    return result;
};

function UsersPage() {
    const classes = useStyles();
    const [userInfo, setUserInfo] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const { id } = useParams();
    const query = firebase.firestore().collection('users').where("company","==", id);
    const confirm = useConfirm();

    const [users, usersLoading, error] = useCollectionData(
        query, {"idField": "id"}
    );
    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const storageRef = await firebase.storage().ref().child(id).child('profilePhotos').child(`${uuidv1()+"."+file.name.split('.').pop()}`);
        const snapshot = await storageRef.put(file);
        const path = await snapshot.ref.getDownloadURL();
        setUserInfo({...userInfo, photoURL: path})
    };

    return (<div className={classes.root}>
        <PlusValuesAppBar drawerWidth={drawerWidth}/>
        <CompanySidebar id={id}/>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <MaterialTable
                    emptyRowsWhenPaging={false}
                    icons={tableIcons}
                    localization={{
                        toolbar: {
                            searchPlaceholder: "Søk",
                            searchTooltip: "Søk",
                        },
                        header: {
                            actions: ''
                        },
                    }}
                    columns={[
                    {field:"photoURL",title:"",render:(data)=>data.photoURL&&<img src={data.photoURL} className={classes.profileImage}/>},
                    {field: "name",title: "Navn"},
                    {field: "email",title: "Epost"},
                    {field: "jobTitle",title: "Stilling"},
                    {field: "team",title: "Avdeling"},
                        {field: "admin",title: "Admin", type:"boolean"},
                ]} data={users} title={""}
                    options={{actionsColumnIndex: -1, paging: false}}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Legg til',
                            isFreeAction: true,
                            onClick: async (event) => {
                                setUserInfo({name:'', email:'', phone:'', jobTitle: '', team: '', admin: false, company:id, sex: ''});
                            }
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Slett',
                            onClick: async (event, rowData) => {
                                try {
                                    await confirm({title: 'Vil du slette ' + (rowData.name || 'ukjent') + '?'});
                                    await firebase.firestore().collection('users').doc(rowData.id).delete();
                                } catch (error) {
                                    if (error) {
                                        console.error(error);
                                    }
                                }
                            }
                        },
                        {
                            icon: tableIcons.ResendPassword,
                            tooltip: 'Send passord',
                            onClick: async (event, rowData) => {
                                try {
                                    await confirm({title: 'Vil du sende ett nytt passord til ' + rowData.email + '?'});
                                    const token = await firebase.auth().currentUser.getIdToken();
                                    await fetch('https://europe-west1-plus-values.cloudfunctions.net/resetPassword?email=' + rowData.email, {
                                        cache: 'no-cache',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + token
                                        }
                                    });
                                } catch (error) {
                                    if (error) {
                                        console.error(error);
                                    }
                                }
                            }
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Rediger',
                            onClick: async (event, rowData) => {
                                setUserInfo({...rowData, birthday: rowData.birthday&&rowData.birthday.toDate?rowData.birthday.toDate():rowData.birthday, hireDate: rowData.hireDate&&rowData.hireDate.toDate?rowData.hireDate.toDate():rowData.hireDate,sex:rowData.sex||'' });
                            }
                        }
                    ]}

                />
        </Container>

            {userInfo && <Dialog open={true} onClose={()=>setUserInfo(null)} aria-labelledby="form-dialog-title">
                <form autoComplete="off">
                    <DialogContent>

                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField className={classes.dialogTextField}
                                           autoFocus
                                           margin="dense"
                                           id="name"
                                           label="Navn"
                                           type="text"
                                           fullWidth
                                           value={userInfo.name}
                                           onChange={(event)=>{
                                               setUserInfo({...userInfo,name:event.target.value});
                                           }}
                                           required
                                />
                                <TextField className={classes.dialogTextField}
                                           margin="dense"
                                           id="email"
                                           label="E-post"
                                           type="email"
                                           fullWidth
                                           value={userInfo.email}
                                           onChange={(event)=>{
                                               setUserInfo({...userInfo,email:event.target.value});
                                           }}
                                           required
                                />
                                <TextField className={classes.dialogTextField}
                                           margin="dense"
                                           id="phone"
                                           label="Telefon"
                                           type="phone"
                                           fullWidth
                                           value={userInfo.phone}
                                           onChange={(event)=>{
                                               setUserInfo({...userInfo,phone:event.target.value});
                                           }}
                                />
                                <TextField className={classes.dialogTextField}
                                           margin="dense"
                                           id="jobTitle"
                                           label="Stilling"
                                           type="text"
                                           fullWidth
                                           value={userInfo.jobTitle}
                                           onChange={(event)=>{
                                               setUserInfo({...userInfo,jobTitle:event.target.value});
                                           }}
                                />
                                <TextField className={classes.dialogTextField}
                                           margin="dense"
                                           id="team"
                                           label="Avdeling"
                                           type="text"
                                           fullWidth
                                           value={userInfo.team}
                                           onChange={(event)=>{
                                               setUserInfo({...userInfo,team:event.target.value});
                                           }}
                                />
                                <KeyboardDatePicker className={classes.dialogTextField}
                                                    disableFuture
                                                    variant="inline"
                                                    format="DD/MM YYYY"
                                                    margin="normal"
                                                    id="hireDate"
                                                    label="Ansettelsdato"
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    value={userInfo.hireDate?moment(userInfo.hireDate):null}
                                                    onChange={(date)=>{
                                                        setUserInfo({...userInfo,hireDate:date?date.toDate():null});
                                                    }}
                                                    autoOk
                                                    fullWidth
                                />
                                <KeyboardDatePicker className={classes.dialogTextField}
                                                    disableFuture
                                                    variant="inline"
                                                    format="DD/MM YYYY"
                                                    margin="normal"
                                                    id="birthdate"
                                                    label="Fødselsdag"
                                                    value={userInfo.birthday?moment(userInfo.birthday):null}
                                                    onChange={(date)=>{
                                                        setUserInfo({...userInfo,birthday:date?date.toDate():null});
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    autoOk
                                                    fullWidth
                                />
                                <FormControl className={classes.formControl} margin="dense" fullWidth>
                                    <InputLabel id="sexLabel">Kjønn</InputLabel>
                                    <Select
                                        labelId="sexLabel"
                                        id="sex"
                                        fullWidth
                                        value={userInfo.sex}
                                        onChange={(event)=>{
                                            setUserInfo({...userInfo,sex:event.target.value});
                                        }}
                                    >
                                        <MenuItem key="male" value="male">Mann</MenuItem>
                                        <MenuItem key="female" value="female">Kvinne</MenuItem>
                                        <MenuItem key="other" value="other">Annet</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <Dropzone
                                    onDrop={onDrop}
                                    accept="image/png, image/gif image/jpg"
                                    minSize={0}
                                    maxSize={maxSize}
                                >
                                    {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                          isDragReject
                                      }) => {
                                        return (
                                            <div {...getRootProps({className: classes.dropZone})}>
                                                <div className={classes.logoContainer}>
                                                    {userInfo.photoURL && <img src={userInfo.photoURL} alt="photo"/>}
                                                </div>
                                                <input {...getInputProps()} />
                                                {isDragActive
                                                    ? "Slipp filen din her!"
                                                    : "Last opp profil bilde."}
                                                {isDragReject && "Det er bare mulig å laste opp en jpeg, gif eller png fil som logo!"}
                                            </div>
                                        );
                                    }}
                                </Dropzone>
                                <FormControlLabel
                                    control={<Checkbox checked={userInfo.admin} onChange={(event)=>setUserInfo({...userInfo,admin:event.target.checked})} />}
                                    label="Admin"
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event)=>setUserInfo(null)}>
                            Avbryt
                        </Button>
                        <Button onClick={async (event)=>{
                            if (event.currentTarget.form.reportValidity()) {
                                setSaving(true);
                                try {
                                    if (userInfo.id) {
                                        const cleanUserInfo = cleanMap(userInfo);
                                        await firebase.firestore().doc(`users/${userInfo.id}`).update(
                                            cleanUserInfo
                                        );
                                    } else {
                                        userInfo.company = id;
                                        const token = await firebase.auth().currentUser.getIdToken();
                                        const response = await fetch('https://europe-west1-plus-values.cloudfunctions.net/addUser', {
                                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                            mode: 'cors', // no-cors, *cors, same-origin
                                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer ' + token
                                            },
                                            body: JSON.stringify({
                                                email: userInfo.email,
                                                phoneNumber: userInfo.phone,
                                                name: userInfo.name,
                                                phone: userInfo.phone,
                                                jobTitle: userInfo.jobTitle||null,
                                                language: window.navigator.language,
                                                photoURL: userInfo.photoURL||null,
                                                team: userInfo.team,
                                                admin: !!userInfo.admin,
                                                sex: userInfo.sex || null,
                                                hireDate: userInfo.hireDate || null,
                                                birthday: userInfo.birthday || null,
                                                company: id
                                            })
                                        });
                                        const result = await response.json();
                                    }
                                    setUserInfo(null)
                                } finally {
                                    setSaving(false);
                                }
                            }
                        }} color="primary">

                            {saving ?
                            <CircularProgress size={14} /> :
                            <Typography variant="button">Lagre</Typography>}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>}
        </main>
    </div>);
}

export default UsersPage;