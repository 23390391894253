import React, {Fragment, useEffect} from "react";
import {makeStyles, Typography} from "@material-ui/core";
import PlusValuesAppBar from "../components/PlusValuesAppBar";
import Box from "@material-ui/core/Box";
import CompanySidebar from "../components/CompanySidebar";
import {useParams} from "react-router-dom";
import IframeResizer from "iframe-resizer-react";

const drawerWidth = 240;

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: theme.mixins.toolbar,
}));
function StatsPage() {
    const classes = useStyles();
    const { id } = useParams();

    return (<div className={classes.root}>
        <PlusValuesAppBar drawerWidth={drawerWidth}/>
        <CompanySidebar id={id}/>
        <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box p={5}>
            <IframeResizer width="100%" height="900px"
                //heightCalculationMethod="documentElementScroll"
                    src={`https://datastudio.google.com/embed/reporting/527affb8-fca2-4fa6-90f8-90147f062ea8/page/toiOB?params=${encodeURIComponent(JSON.stringify({company:id}))}`}
                    frameBorder="0" style={{"border":0}} allowFullScreen/>
        </Box>
        </main>
    </div>);
}

export default StatsPage;