import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, {useCallback, useEffect} from "react";
import firebase from "firebase";
import {useCollectionData, useCollectionDataOnce} from "react-firebase-hooks/firestore";
import Grid from "@material-ui/core/Grid";
import {produce} from "immer";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Dropzone from "react-dropzone";

const useStyles = makeStyles( theme=>({
    dialogTextField: {

    },
    header: {
        fontWeight: "bold",
    },
    headerCell: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        marginTop: 10
    },
    table: {

    },
    rowTitle: {
        borderBottom: "1px solid #eeeeee",
        paddingTop: 8
    },
    row: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
    }
}));

const getUserTeam = (teams, userId) => {
    if (teams) {
        const team = teams.find(t=>t.members.includes(userId));
        return team?team.name:null;
    }
    return null;
}

const assignUserToTeam = (teams, userId, team) => {
    const currentTeam = teams.find(t=>t.members.includes(userId));
    if (currentTeam) {
        if (currentTeam.name === team) {
            return teams;
        } else {
            currentTeam.members=currentTeam.members.filter(item => item!==userId);
        }
    }
    const updatedTeam = currentTeam && currentTeam.members.length===0?teams.filter(t=>t.name!==currentTeam.name):teams;
    const newTeam = updatedTeam.find(t=>t.name === team);
    if (newTeam) {
        newTeam.members = [...newTeam.members, userId];
        return updatedTeam;
    }
    if (!team||team.length===0) {
        return updatedTeam;
    }
    const createdTeam = {name: team, members: [userId], score: 0};
    return [...updatedTeam,createdTeam];
}

const IMAGE_MAX_SIZE = 1048576*5; //5mb


function ChallengeDialog({challenge,setOpenChallenge,id}) {
    const company_id = id;
    const [saving, setSaving] = React.useState(false);
    const [newFile, setNewFile] = React.useState(undefined);
    const classes = useStyles();
    const [users, loading, error] = useCollectionData(
        firebase.firestore().collection('users').where("company","==", id).orderBy("name","asc"), {"idField": "id"}
    );

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        setNewFile(file);
        console.log(file);
        //const reader = new FileReader()
        // const storageRef = await firebase.storage().ref().child(id).child(`${file.name}`);
        // const snapshot = await storageRef.put(file);
        // const path = await snapshot.ref.getDownloadURL();
        // await docRef.update({logo:path});
    }, []);
    if (error) {
        console.error(error);
    }

    return (<Dialog open={true} onClose={()=>setOpenChallenge(null)} aria-labelledby="form-dialog-title">
        <form autoComplete="off">
            <DialogContent>
                <TextField className={classes.dialogTextField}
                           autoFocus
                           margin="dense"
                           id="title"
                           label="Verdi"
                           type="text"
                           fullWidth
                           value={challenge.title}
                           onChange={(event)=>{
                               setOpenChallenge({...challenge,title:event.target.value});
                           }}
                           required
                />

                <TextField className={classes.dialogTextField}
                           margin="dense"
                           id="tagline"
                           label="Utfordring"
                           type="text"
                           fullWidth
                           value={challenge.tagline}
                           onChange={(event)=>{
                               setOpenChallenge({...challenge,tagline:event.target.value});
                           }}
                           required
                />
                <KeyboardDatePicker className={classes.dialogTextField}
                                    variant="inline"
                                    format="DD/MM YYYY"
                                    margin="normal"
                                    id="startDate"
                                    label="Start"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={challenge.startDate?challenge.startDate.toDate():null}
                                    onChange={(date)=>{
                                        // console.log('This `un', date);
                                        setOpenChallenge({...challenge,startDate:firebase.firestore.Timestamp.fromDate(date.toDate())});
                                        //     setUserInfo({...userInfo,hireDate:date?date.toDate():null});
                                    }}
                    // value={userInfo.hireDate?moment(userInfo.hireDate):null}
                                    // onChange={(date)=>{
                                    //     setUserInfo({...userInfo,hireDate:date?date.toDate():null});
                                    // }}
                                    autoOk
                                    fullWidth
                />
                <KeyboardDatePicker className={classes.dialogTextField}
                                    variant="inline"
                                    format="DD/MM YYYY"
                                    margin="normal"
                                    id="endDate"
                                    label="Slutt"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    value={challenge.endDate?challenge.endDate.toDate():null}
                                    onChange={(date)=>{
                                        // console.log('This `un', date);
                                        setOpenChallenge({...challenge,endDate:firebase.firestore.Timestamp.fromDate(date.toDate())});
                                        //     setUserInfo({...userInfo,hireDate:date?date.toDate():null});
                                    }}

                                    autoOk
                                    fullWidth
                />
                {/*<FormControl className={classes.formControl} margin="dense" fullWidth>*/}
                {/*    <InputLabel id="monthLabel">Måned</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="monthLabel"*/}
                {/*        id="startMonth"*/}
                {/*        fullWidth*/}
                {/*        value={challenge.startMonth?moment(challenge.startMonth.toDate()).format():null}*/}
                {/*        onChange={(event)=>{*/}
                {/*            setOpenChallenge({...challenge,startMonth:firebase.firestore.Timestamp.fromDate(moment(event.target.value).toDate())});*/}
                {/*        }}*/}
                {/*        required*/}
                {/*    >*/}
                {/*        {[...Array(12)].map((a,i)=>{*/}
                {/*            const date = moment().startOf('month').add(i, 'month');*/}
                {/*            return <MenuItem value={date.format()} key={i}>{date.format("MMMM YYYY")}</MenuItem>;*/}
                {/*        })}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField className={classes.dialogTextField}
                                   margin="dense"
                                   id="tagline"
                                   label="Maksverdi"
                                   type="number"
                                   fullWidth
                                   value={challenge.goal}
                                   onChange={(event)=>{
                                       setOpenChallenge({...challenge,goal:Number(event.target.value)});
                                   }}
                                   required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl} margin="dense" fullWidth>
                            <InputLabel id="goalLabel">Mål</InputLabel>
                            <Select
                                labelId="goalLabel"
                                id="goal"
                                fullWidth
                                value={!challenge.success?0:challenge.success}
                                onChange={(event)=>{
                                    setOpenChallenge({...challenge,success:Number(event.target.value)});
                                }}
                                required
                            >
                                {[...Array(10)].map((a,i)=>{
                                    return <MenuItem value={i+1} key={i}>{(i+1)+"0%"}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {users && <Grid container spacing={3}>
                    <Grid item xs={6} className={classes.headerCell}><Typography className={classes.header}>Ansatt</Typography></Grid>
                    <Grid item xs={6} className={classes.headerCell}><Typography className={classes.header}>Lag</Typography></Grid>
                    {users && users.map((user,index) => (
                        (<Grid item xs={12} className={classes.row} key={index}><Grid container spacing={3}>
                            <Grid item xs={6}><Typography className={classes.rowTitle}>{user.name}</Typography></Grid>
                            <Grid item xs={6}><Autocomplete
                                id="select-team"
                                options={challenge.teams.map((team)=>team.name)}
                                value={getUserTeam(challenge.teams, user.id)}
                                onChange={(event, team) => {
                                    setOpenChallenge(produce(challenge,(draft)=>{
                                        draft.teams = assignUserToTeam(draft.teams, user.id, team);
                                    }));
                                }}
                                onInputChange={(event, team) => {
                                    setOpenChallenge(produce(challenge,(draft)=>{
                                        draft.teams = assignUserToTeam(draft.teams, user.id, team);
                                    }));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            /></Grid></Grid>
                        </Grid>)
                    ))}
                </Grid>}
                <Grid container spacing={3} style={{
                    paddingTop: '2rem'
                }}>
                    <Grid item xs={6}>
                    <Dropzone
                        onDrop={onDrop}
                        accept="image/png, image/gif, image/jpeg"
                        minSize={0}
                        maxSize={IMAGE_MAX_SIZE}
                    >
                        {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragReject
                          }) => {
                            return (
                                <div {...getRootProps({className: classes.dropZone})}>
                                    <div className={classes.logoContainer}>
                                        {(challenge.image || newFile) && <img style={{
                                            maxWidth: '15rem'
                                        }} src={newFile ? URL.createObjectURL(newFile) : challenge.image}/>}
                                    </div>
                                    <input {...getInputProps()} />
                                    {isDragActive
                                        ? "Slipp filen din her!"
                                        : "Trykk her eller trekk en fil hit for å laste opp bakgrunn."}
                                    {isDragReject && "Det er bare mulig å laste opp en jpeg, gif eller png fil som logo!"}
                                </div>
                            );
                        }}
                    </Dropzone>
                </Grid>
                {/*    <Grid item xs={6}>*/}
                {/*    <Dropzone*/}
                {/*        // onDrop={onDrop}*/}
                {/*        accept="image/png, image/gif image/jpg"*/}
                {/*        minSize={0}*/}
                {/*        maxSize={IMAGE_MAX_SIZE}*/}
                {/*    >*/}
                {/*        {({*/}
                {/*              getRootProps,*/}
                {/*              getInputProps,*/}
                {/*              isDragActive,*/}
                {/*              isDragReject*/}
                {/*          }) => {*/}
                {/*            return (*/}
                {/*                <div {...getRootProps({className: classes.dropZone})}>*/}
                {/*                    <div className={classes.logoContainer}>*/}
                {/*                        {challenge.image && <img src={challenge.image}/>}*/}
                {/*                    </div>*/}
                {/*                    <input {...getInputProps()} />*/}
                {/*                    {isDragActive*/}
                {/*                        ? "Slipp filen din her!"*/}
                {/*                        : "Trykk her eller trekk en fil hit for å laste opp logo."}*/}
                {/*                    {isDragReject && "Det er bare mulig å laste opp en jpeg, gif eller png fil som logo!"}*/}
                {/*                </div>*/}
                {/*            );*/}
                {/*        }}*/}
                {/*    </Dropzone>*/}
                {/*</Grid>*/}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event)=>setOpenChallenge(null)}>
                    Avbryt
                </Button>
                <Button onClick={async (event)=>{
                    if (event.currentTarget.form.reportValidity()) {
                        setSaving(true);
                        try {
                            const {id, teams, ...updates} = challenge;

                            let doc;
                            if (!id) {
                                doc = await firebase.firestore().collection('challenges').add(updates);
                                teams.forEach((team)=>{
                                    firebase.firestore().collection('challenges').doc(doc.id).collection('teams').add({...team,score:0});
                                });
                            } else {
                                doc = firebase.firestore().collection('challenges').doc(id)
                                await doc.update(updates);
                                const teamsRef = firebase.firestore().collection('challenges').doc(id).collection("teams");
                                const storedTeams = await teamsRef.get();
                                const promises = [];
                                const uploaded = [];
                                for (const storedTeam of storedTeams.docs) {
                                    const t = teams.find((team) => team.name === storedTeam.data().name);
                                    if (t) {
                                        promises.push(storedTeam.ref.update({...t,score:storedTeam.data().score}));
                                        uploaded.push(t);
                                    } else {
                                        promises.push(storedTeam.delete());
                                    }
                                }
                                teams.filter((t) => !uploaded.includes(t)).forEach((t) => {
                                    promises.push(teamsRef.add(t));
                                });
                                await Promise.all(promises);
                            }
                            if(newFile) {
                                const storageRef = await firebase.storage().ref().child(company_id).child(doc.id).child(`${newFile.name}`);
                                const snapshot = await storageRef.put(newFile);
                                const path = await snapshot.ref.getDownloadURL();
                                // console.log(path);
                                await doc.update({image:path});
                            }
                            setOpenChallenge(null);
                        } finally {
                            setSaving(false);
                        }
                    }
                }} color="primary">

                    {saving ?
                        <CircularProgress size={14} /> :
                        <Typography variant="button">Lagre</Typography>}
                </Button>
            </DialogActions>
        </form>
    </Dialog>);
}

export default ChallengeDialog;
