import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import {useHistory} from "react-router-dom"
import {makeStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ArrowBack} from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles( theme=>({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    appBarSpacer: theme.mixins.toolbar,
}));

function CompanySidebar({id}) {
    const classes = useStyles();
    const history = useHistory();
    return <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
            paper: classes.drawerPaper,
        }}
        anchor="left"
    >
        <div className={classes.appBarSpacer} />
        <Divider />
        <List>
            <ListItem button>
                <ListItemText primary="Organisasjon" onClick={()=>{
                    history.push(`/company/${id}`);
                }}/>
            </ListItem>
            <ListItem button>
                <ListItemText primary="Brukere" onClick={()=>{
                    history.push(`/company/${id}/users`);
                }}/>
            </ListItem>
            <ListItem button>
                <ListItemText primary="Utfordringer" onClick={()=>{
                    history.push(`/company/${id}/content`);
                }}/>
            </ListItem>
            <ListItem button>
                <ListItemText primary="Statistikk" onClick={()=>{
                    history.push(`/company/${id}/stats`);
                }}/>
            </ListItem>
            <ListItem button>
                <ListItemText primary="Plus TV" onClick={()=>{
                    history.push(`/company/${id}/tv`);
                }}/>
            </ListItem>
            <ListItem button onClick={()=>{
                history.push(`/`);
            }}>
                <ListItemIcon>
                    <ArrowBack />
                </ListItemIcon>
                <ListItemText primary="Tilbake" />
            </ListItem>
        </List>
        <Divider />
    </Drawer>;
}

export default CompanySidebar;